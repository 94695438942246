import { QueryFeature } from "../../feature/query-feature";
import { FieldsInfo } from "../../feature/fields-info";
import { ResourceFilter } from "../../filter/resource-filter";
import { LegendDescription } from "../../legend/legend-description";
import { EsriProvider } from "@wega-providers/esri.provider";
import { AppConfigProvider } from "@shared/providers/config.provider";
import { MapService } from "@domain/data/resource/map-service";
import { ConfigService } from "@shared/config/config-service";
import { MapClickPoint } from "@domain/data/structures/map-click-point";
import { LayerData } from "@domain/data/structures/layer-data";
import { GeoExtent } from "@domain/data/structures/extent";
import { WegaServiceCapabilities } from "../service-capabilities";
import { LocaleProvider } from "src/app/modules/i18n/providers/i18n.provider";
import { ServiceState } from "@shared/wega-utils/wega-enums";

export class WfsService implements MapService {
  state: ServiceState;
  canUseFilter: boolean = false;
  layer: any;

  constructor(
    private config: ConfigService,
    private globalConfig: AppConfigProvider,
    private esri: EsriProvider,
    private locale: LocaleProvider,
    private capabilities: WegaServiceCapabilities
  ) {
    this.esri.setProxy(this.config);
  }

  getPointFeatureInfo(pCoordinates: MapClickPoint): Promise<QueryFeature[]> {
    throw new Error("Method not implemented.");
  }

  getExtentFeatureInfo(pExtent: GeoExtent): Promise<QueryFeature[]> {
    throw new Error("Method not implemented.");
  }

  async getLayer(): Promise<any> {
    const [WFSLayer, Extent, SpatialReference] = await this.esri.loadModules(["esri/layers/WFSLayer", "esri/geometry/Extent", "esri/SpatialReference"]);

    //this.esri.esriConfig.defaults.io.corsEnabledServers.push(this.config.url);

    // window["WFSL"] = WFSLayer;

    let layersList = this.config.layers && this.config.layers.join ? this.config.layers.join("|") : [];
    let wfsOptions = {
      url: this.config.url,
      name: layersList,
      maxFeatures: this.config.maxFeatures,
      mode: "ondemand",
      version: this.config.version || "1.0.0",
      wkid: 3857,
      customParameters: {},
    };

    //console.log(wfsOptions);

    // почему не используется EsriProvider.setProxy? непонятно...
    // this.config.proxy = this.globalConfig.Environment.CorsScript;

    if (this.config.proxy) {
      this.esri.esriConfig.defaults.io.alwaysUseProxy = false;
      this.esri.urlUtils.addProxyRule({
        urlPrefix: this.config.url,
        proxyUrl: this.config.proxy,
      });
    }

    this.layer = new WFSLayer({});
    this.layer.fromJson(wfsOptions);
    this.layer.setVisibility(true);

    // hack
    return this.layer;
  }

  loadLayerInfo(): Promise<FieldsInfo> {
    return null;
  }

  getAttributesByID(featureID: string): Promise<any> {
    throw new Error("Method not implemented.");
  }

  async getFeatureInfo(pCoordinates: MapClickPoint): Promise<any> {
    ///SODD: https://community.esri.com/thread/207114-how-to-select-graphics-from-graphics-layer-inside-a-clicked-buffer
    const [Circle, Point, SpatialReference] = await this.esri.loadModules([
      "esri/geometry/Circle",
      "esri/geometry/Point",
      "esri/SpatialReference",
      "esri/geometry/Geometry",
    ]);
    let pntCircle = new Circle([pCoordinates.x, pCoordinates.y], {
      radius: pCoordinates.radius,
      radiusUnit: "esriMeters",
    });

    let spatRefObject = new SpatialReference(pCoordinates.srs);
    pntCircle.setSpatialReference(spatRefObject);
    let pnt = new Point(pCoordinates.x, pCoordinates.y, spatRefObject);

    let ans: QueryFeature[] = [];
    for (let gr of this.layer.graphics) {
      if ((gr.geometry.geometryType === "point" && pntCircle.contains(gr.geometry)) || (gr.geometry.geometryType !== "point" && gr.geometry.contains(pnt))) {
        const newF = new QueryFeature(this.config, this.capabilities, this.locale);
        newF.addArcGISAtributes("map", gr);
        ans.push(newF);
      }
    }

    return ans;
  }

  getFeaturesByQuery(pFilter: ResourceFilter): Promise<[QueryFeature[], boolean]> {
    throw new Error("Method not implemented.");
  }

  setFilter(pFilter: ResourceFilter) {
    return null;
  }

  saveFeatures(QueryFeature: any): Promise<boolean> {
    throw new Error("Method not implemented.");
  }

  public getLegend(): LegendDescription[] {
    return null;
  }

  public async loadSpatialData(): Promise<LayerData[]> {
    return null;
  }
}
