import { ResourceFilter } from "../../filter/resource-filter";
import { LegendDescription } from "../../legend/legend-description";
import { QueryFeature } from "../../feature/query-feature";
import { FieldsInfo } from "../../feature/fields-info";
import { MapService } from "@domain/data/resource/map-service";
import { ConfigService } from "@shared/config/config-service";
import { LayerData } from "@domain/data/structures/layer-data";
import { GeoExtent } from "@domain/data/structures/extent";
import { loadModules } from "esri-loader";
import { EsriProvider } from "@wega-providers/esri.provider";
import { ServiceState } from "@shared/wega-utils/wega-enums";

export class ArcGisFeatureService implements MapService {
  state: ServiceState;
  canUseFilter: boolean = true;
  editableLayer: any = null;
  config: ConfigService;

  constructor(config: ConfigService, public esri: EsriProvider) {
    this.config = config;

    this.esri.setProxy(this.config);
  }

  async queryFeatures(queryParams) {
    const editLayer = await this.GetEditableLayer();

    return new Promise((resolve, reject) => {
      editLayer.queryFeatures(queryParams, resolve, reject);
    });
  }

  convertStringByType(str, type) {
    switch (type) {
      case "esriFieldTypeSmallInteger":
      case "esriFieldTypeInteger":
      case "esriFieldTypeSingle":
        return parseInt(str, 10);

      case "esriFieldTypeDouble":
        return parseFloat(str.replace(",", "."));

      // "esriFieldTypeString"
      // "esriFieldTypeDate"
      // "esriFieldTypeOID"
      // "esriFieldTypeGeometry"
      // "esriFieldTypeBlob"
      // "esriFieldTypeRaster"
      // "esriFieldTypeGUID"
      // "esriFieldTypeGlobalID"
      // "esriFieldTypeXML"
    }

    return str;
  }

  async converQueryFeatureToGraphic(queryFeature, editLayer) {
    const [Graphic, Query] = await loadModules(["esri/graphic", "esri/tasks/query"]);
    const query = new Query();
    const id = queryFeature.attributes.arcgis.OBJECTID;
    let response, selectedFeature;
    const attrs = queryFeature.attributes.arcgis;

    query.objectIds = [id];
    query.outFields = ["*"];

    response = await this.queryFeatures(query);
    selectedFeature = response.features[0];
    for (const _field in attrs) {
      if (_field === "OBJECTID") {
        continue;
      }

      const serverField = response.fields.find((_f) => _f.name === _field || _f.alias === _field);

      if (serverField) {
        selectedFeature.attributes[serverField.name] = this.convertStringByType(attrs[_field], serverField.type);
      }
    }

    return selectedFeature;
  }

  async saveFeatures(queryFeature: QueryFeature): Promise<boolean> {
    const fLayer = await this.GetEditableLayer();
    const gr = await this.converQueryFeatureToGraphic(queryFeature, fLayer);
    const result = await fLayer.applyEdits(null, [gr], null);

    return new Promise(() => null);
  }

  async GetEditableLayer() {
    if (this.editableLayer == null) {
      const [FeatureLayer] = await loadModules(["esri/layers/FeatureLayer"]);

      this.editableLayer = new FeatureLayer(this.config.url);

      return this.editableLayer;
    } else {
      return this.editableLayer;
    }
  }

  getLayer(): Promise<any> {
    throw new Error("Method not implemented.");
  }

  loadLayerInfo(): Promise<FieldsInfo> {
    throw new Error("Method not implemented.");
  }

  getAttributesByID(featureID: string): Promise<any> {
    throw new Error("Method not implemented.");
  }

  getPointFeatureInfo(coordinates: any): Promise<any> {
    throw new Error("Method not implemented.");
  }

  getFeaturesByQuery(filter: ResourceFilter): Promise<[QueryFeature[], boolean]> {
    throw new Error("Method not implemented.");
  }

  setFilter(filter: any) {
    throw new Error("Method not implemented.");
  }

  getExtentFeatureInfo(extent: GeoExtent): Promise<QueryFeature[]> {
    throw new Error("Method not implemented.");
  }

  public getLegend(): LegendDescription[] {
    return null;
  }

  public async loadSpatialData(): Promise<LayerData[]> {
    return null;
  }
}
