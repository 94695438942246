import { environment } from "src/environments/environment";
import { MAP_MODE, MAP_SELECTON_MODE, MAP_MEASURE_MODE } from "./wega-enums";

export type QueryHoverFilter = {
  field: string;
  title: string;
  predicate: (ats) => boolean;
  useForExtent: () => boolean;
};

export type SpatialFilterInfo = {
  service: any;
  field: string;
};

export class WegaAppConfig {
  /** Заголовок сайта. */
  PageTitle: string = "ЦДНР (локальный)";

  /** Считывается при загрузке основного сайта ("нулевого" подсайта). */
  InitDefaultPath: string = "./assets/config/init-default.js";

  /** Считывается при загрузке подсайта. */
  InitOverridePath: string = "./assets/config/init-override.js";

  /** URL JSON-файла со списком корневых каталогов. */
  RootCatalogUrl: string = "./assets/catalogs/_root.json";

  /** URL JSON-файла со параметрами пространственного фильтра. */
  FiltersUrl: string = "./assets/config/filters.json";

  /** URL JSON-файла с override-параметрами конфигурации веб-приложения. */
  InitJsonUrl: string = "./assets/config/init.json";

  /** URL точки доступа для проксирования ресурсов (для обхода same-origin policy). */
  CorsScript: string = window.location.protocol + "//wega-old.staging.vsegei.ru/proxy.php?csurl=";

  /** Форсировать использование HTTPS (если ВЕГА открыта через https). */
  TransformHttpToHttps: boolean = true;

  /** Показывать элемент управления масштабом. */
  AllowChangeScale: boolean = true;

  /** Скачивать больше одного файла в виде zip-архива. */
  DownloadMultipleFilesAsZip: boolean = true;

  /** Разрешать точечный запрос в режиме перетаскивания карты. */
  EnableSelectWhilePan: boolean = true;

  /** Не показывать пустые атрибутивные поля. */
  HideEmptyAttributeValues: boolean = true;

  /** Интервал времени, по истечении которого над неподвижным курсором будет выведена подсказка о текущем участке (на котором остановлен курсор). */
  QueryHoverMapInterval: number = 300;

  /** Выводить подсказку только при явном запросе через контекстное меню. */
  QueryOnlyOnContextMenu: boolean = false;

  /** Вывоить подсказку о текущем участке при неподвижном курсоре. */
  ShowQueryResultOnMapHover: boolean = false;

  /** Отображать панель каталога в открытом виде. */
  ShowCatalogs: boolean = true;

  /** По умолчанию открыть блок слоев ресурса в панели отобранных карт. */
  ShowLayersList: boolean = false;

  /** По умолчанию открыть блок картографической легенды ресурса в панели отобранных карт. */
  ShowLegend: boolean = false;

  /** По умолчанию открыть блок метаданных ресурса в панели отобранных карт. */
  ShowMetadata: boolean = false;

  /** По умолчанию открыть блок настроек вида ресурса в панели отобранных карт. */
  ShowView: boolean = false;

  /** По умолчанию открыть блок фильтра ресурса в панели отобранных карт. */
  ShowFilter: boolean = false;

  /** Отключить панель переключения панелей каталога, отобранных карт и пр. (за исключением панели запроса данных). */
  HideLeftPanelExceptInfo: boolean = false;

  /** Показывать кнопку перехода к текущему местоположению. */
  ShowLocateButton: boolean = true;

  /** Выводить обзорную карту при запуске веб-приложения. */
  ShowOverviewMap: boolean = false;

  /** Выводить кнопку учетной записи пользователя. */
  ShowProfileButton: boolean = true;

  /** Выводить кнопку сведений о веб-приложении. */
  ShowAboutButton: boolean = true;

  /** Выводить информацию о текущем картографическом местоположении. */
  TrackCurrentPosition: boolean = true;

  /** Выводить масштабную линейку. */
  UseScalebar: boolean = true;

  /** Выводить поисковую строку. */
  UseSearch: boolean = true;

  /** Выводить кнопку экспорта и печати карты. */
  UseExportButton: boolean = true;

  /** Исходная ширина рабочих панелей. */
  PanelWidth: number = 400;

  /** Идентификатор стартовой подложки. */
  BasemapId: string = "eako";

  /** Список наименований разрешенных подложек. */
  AllowedBaseMaps: string[] = [
    "31a8f8ac3edc440c887af79e9ac333cf",
    "48c72668d1694f5abfecc06b46af5b6b",
    "338e4a96753041749b4e176488c4fd28",
    "ffbe8c0582e64d869d02d6a3cf6c666a",
    "c111cc0b7a4944e3ba2bb68867158c82",
    "4780e94cd7a74bb38dba2f5bf8bb270f",
    "48b0ca55b4a644699448bfe4184cfe37",
    "949ac433044d4678b6fd9aceaf8acd87",
    "70e33dd82e8f4aa4954cd339b1fb27f9",
    "relief",
    "eako",
  ].filter((x) => !environment.production || -1 !== ["31a8f8ac3edc440c887af79e9ac333cf", "relief", "eako"].indexOf(x));

  /** Коллекция ресурсов (типизированных как JSONConfigResource), автоматически включаемых при запуске веб-приложения. */
  StartupMaps: any[] = [
    {
      id: "-base-0",
      title: "Номенклатурная разграфка",
      pinned: true,

      service: {
        supportClick: false,
        id: "arcgis",
        title: "Номенклатурная разграфка",
        type: "arcgis",
        url: "https://agssrv1.vsegei.ru/arcgis/rest/services/BaseMaps/nomlists/MapServer",
        layers: null,
      },
    },
  ];

  /** Масштабный коэффициент карты, используемый по умолчанию при запуске веб-приложения. */
  Zoom: number = 4; // 3

  /** Координаты центра карты, используемые по умолчанию при запуске веб-приложения. */
  Center: [number, number] = [94.78712499999327, 62.799467850253144]; // [78, 65]

  /** Режим работы карты, активируемый по умолчанию при запуске веб-приложения. */
  MapMode: MAP_MODE = MAP_MODE.PAN;

  /** Режим запроса данных карты, используемый по умолчанию. */
  MapSelectionMode: MAP_SELECTON_MODE = MAP_SELECTON_MODE.SELECT_POINT;

  /** Режим измерения расстояний и площадей, используемый по умолчанию. */
  MapMeasureMode: MAP_MEASURE_MODE = MAP_MEASURE_MODE.MEASURE_LENGTH;

  /** Текст сведений о ресурсе. */
  AboutText: string = [
    "Структурированный массив цифровой геологической информации 'ГИС-Атлас «Недра России»' формируется Институтом Карпинского ",
    "в рамках выполнения Государственного задания №049-00016-21-00 на 2021 г. и плановый период 2022 и 2023 годы от 14.01.2021г., ",
    "обеспечен удаленным доступом к данным на основе согласованных с ФГБУ «Росгеолфонд» (в части взаимодействия с ФГИС ЕФГИ) ",
    "протоколов обмена с возможностью санкционированного использования материалов по сети 'Интернет' посредством сервисов ",
    "автоматизированного представления данных в структурированном машиночитаемом виде.",
  ].join("");

  /** Идентификаторы ресурсов, которые безусловно исключаются при запросе данных по карте. */
  ExcludeResourcesFromSelection: string[] = ["-base-0"];

  /** Словарь допустимых типов ресурсов (идентификатор + название). */
  ResourceTypes: Array<{ title: string; type: string }> = [
    { title: "Все", type: "all" },
    { title: "WMS", type: "wms" },
    { title: "WFS", type: "wfs" },
    { title: "WMTS", type: "wmts" },
    { title: "XYZ", type: "xyz" },
    { title: "ArcGIS MapService", type: "arcgis" },
    { title: "ArcGIS FeatureService", type: "arcgisfeature" },
    { title: "ArcGIS TileService", type: "arcgistiled" },
    { title: "Nextgis TiledService", type: "nextgiswebmap" },
  ] as Array<{ title: string; type: string }>;

  /** Не выводить сообщения. */
  SupressLog: boolean = false;

  /** Таймаут активности индикатора загрузки карты. */
  LoadingAnimationTimeout: number = 20000;

  /** Выводить подробную информацию о доступности источников данных слоев карты. */
  IndicateLayerDataStatus: boolean = false;

  /** Показывать кнопку переключения в режим глобуса. */
  EnableGlobeRendererButton: boolean = !environment.production;

  /** Показывать кнопку переключения в альтернативный рендерер карты (OpenLayers). */
  EnableAlternateRendererButton: boolean = !environment.production;

  /** Показывать переключатель языка. */
  EnableLanguageSwitch: boolean = false;

  /** Язык (локаль) по умолчанию. */
  DefaultLanguage: string = "ru";

  /** Показывать переключатель подложек. */
  EnableBasemapSelection: boolean = true;

  /** Список кастомных подложек. */
  CustomBasemaps: Array<{
    id: string;
    title: string;
    thumbnail: string;
    layers: Array<{ url: string; checkUrl?: string }>;
    proxy?: string;
  }> = [
    {
      id: "eako",
      title: "ЕЭКО РФ",
      thumbnail: "assets/images/eako.png",
      // proxy: wegaEnvironment.CorsScript,
      // proxy: "https://wega-old.staging.vsegei.ru/proxy/",
      // proxy: 'https://tecton-map.staging.vsegei.ru/proxy.php?',
      // proxy: 'https://msb-api.staging.vsegei.ru/proxy?',
      layers: [
        {
          url: "https://pkk.rosreestr.ru/arcgis/rest/services/BaseMaps/BaseMap/MapServer",
          checkUrl: `https://pkk.rosreestr.ru/arcgis/rest/services/BaseMaps/BaseMap/MapServer/tile/4/4/12`,
        },
        {
          url: "https://pkk.rosreestr.ru/arcgis/rest/services/BaseMaps/Anno/MapServer",
          checkUrl: `https://pkk.rosreestr.ru/arcgis/rest/services/BaseMaps/Anno/MapServer/tile/4/4/12`,
        },
      ],
    },
    {
      id: "relief",
      title: "Цифровая модель рельефа РФ",
      thumbnail: "assets/images/relief.png",
      layers: [
        {
          url: "https://agssrv1.vsegei.ru/arcgis/rest/services/etc/DEM_RUSSIA/MapServer",
        },
      ],
    },
  ];

  /** Метод, запускаемый после инициализации фреймворка. */
  OnWegaLaunch: () => void = () => console.log("%cЗапуск веб-ГИС Атласа выполнен успешно", "color: firebrick");

  /** Разрешить вызов контекстного меню. */
  EnableContextMenu: boolean = false;

  /** Идентификаторы каталогов, которые не должны показываться. */
  HiddenCatalog: Array<string> = [];

  /** Идентификаторы ресурсов, которые не должны показываться. */
  HiddenResources: Array<string> = [];

  /** Параметры карты, которая выводится как подложка (поверх всех слоев), но не использует для этого стандартный механизм подложек. */
  UseAsBase: { url: string; hidden: boolean } = void 0;

  /** Начальный экстент. */
  Extent: { xmin: number; ymin: number; xmax: number; ymax: number } = void 0;

  /** Произвольные CSS-стили (правила), которые будут инжектированы на страницу. */
  ArbitraryCss: string[] = [];

  /** Произвольные JS-скрипты, которые будут инжектированы на страницу. */
  ArbitraryJs: string[] = void 0;

  /** Идентификатор используемого провайдера экспорта карты. */
  PrintProvider: string = "agjs-tile"; // ['agjs', 'agjs-tile', 'ol']

  /** Поквзывать панель пространственного фильтра. */
  ShowSpatialFilter: boolean = true;

  /** Поквзывать панель аналитического блока. */
  ShowAnalysis: boolean = true;

  /** Адрес картинки с логотипом. */
  LogoImageURL: string = "assets/images/logokarpinskynew.png"; // "https://www.vsegei.ru/bitrix/templates/vsegei_2018/images/footer-logo.svg";

  /** Адрес, на который будет перенаправлен пользователь при нажатии на логотип. */
  LogoLinkURL: string = "https://www.karpinskyinstitute.ru/";

  /** Показывать загрузочный экран. */
  SplashScreenEnabled: boolean = false;

  /** Логотип, который будет показан на загрузочном экране. */
  SplashScreenLogo: string = "assets/images/rosnedra-logo.svg";

  /** Надпись под логотипом на загрузочном экране. */
  SplashScreenTitle: string = this.PageTitle;

  /** Ссылка на видеоролик, который будет использован на загрузочном экране. */
  SplashScreenVideoURL: string = "assets/videos/Earth_3D_v01_16.mp4";

  /** Задержка в миллисекундах, после которой загрузочный экран будет скрыт. */
  SplashScreenDelay: number = 3000;

  /** URL глобально используемого прокси-сервера (может быть перекрыт настройками конкретного сервиса). */
  ProxyUrl: string = undefined;

  /** Кодировать все запросы, перенаправляемые прокси-серверу (через encodeURIComponent). */
  EncodeProxyRequest: boolean = false;

  /** Идентификатор "страховочной" подложки (будет активирована, если основная не загружается). */
  EmergencyBasemapId: string = "satellite,relief";

  /** Список поисковых модулей. */
  SearchEngines: Array<{
    id: string;
    mode: "default" | "optional";
    arguments?: string[];
  }> = [
    {
      id: "attribute-data",
      mode: "default",
      arguments: [JSON.stringify({ useCache: true })],
    },
    { id: "arcgis-locator", mode: "default" },
    { id: "catalog-resources", mode: "default" },
    {
      id: "unified-api",
      mode: "optional",
      arguments: ["https://mapindex.staging.vsegei.ru"],
    },
    { id: "cached-data", mode: "optional" },
    { id: "spatial-filters", mode: "optional" },
    { id: "geological-monuments", mode: "optional" },
    {
      id: "raster-db",
      mode: "default",
      arguments: ["https://rasterdb.vsegei.ru"],
    },
    {
      id: "llm-api",
      mode: "optional",
      arguments: ["http://172.30.10.77:5555"],
    },
  ];

  /** Размер страницы результатов поиска. */
  SearchResultsPageSize: number = 100;

  /** Отключенные поисковые модули. */
  DisabledSearchEngines: Array<string> = [];

  /** Не показывать кнопку поиска для каждого из найденных атрибутов в результатах поиска. */
  HideSearchButtonForQueryResults: boolean = false;

  /** Выводить кнопку смены проекции. */
  EnableProjections: boolean = !environment.production;

  /** Разрешить вывод вкладки поиска для конкретного ресурса. */
  ShowSearch: boolean = false;

  /** Адрес сервиса, исопльзуемого для генерации DBF-таблицы. */
  DbfServiceUrl: string = window.location.protocol + "//atlaspacket.vsegei.ru/service/";

  /** Разрешить использование "шторки". */
  EnableSwiping: boolean = true;

  /** Список идентификаторов карт (из текущего каталога), которые должны добавляться при старте. */
  StartupMapIdList: string[] = [];

  /** Показать кнопку активации дэшборда. */
  ShowDashboardButton: boolean = false;

  /** Количество результатов запроса, по превышении которого объекты будут выведены в свернутом виде. Если = -1, то все объекты выводятся свернутыми. */
  MaxFeaturesToCollapse: number = -1;

  /** Показать диаграмму на карте. */
  ShowMapChart: boolean = false;

  /** Параметры диаграмм. */
  MapChartParameters: Array<{
    name: string;
    type: "pie";
    url: string;
    path: string;
    colors?: Array<{
      value: string;
      htmlColor: string;
    }>;
  }> = [];

  /** Показать кнопку активации ассистента. */
  ShowAssistantButton: boolean = false;
}
