import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { LegendDescription } from "../../legend/legend-description";
import { ResourceFilter } from "../../filter/resource-filter";
import { QueryFeature } from "../../feature/query-feature";
import { FieldsInfo } from "../../feature/fields-info";
import { MapService } from "@domain/data/resource/map-service";
import { ConfigService } from "@shared/config/config-service";
import { LayerData } from "@domain/data/structures/layer-data";
import { GeoExtent } from "@domain/data/structures/extent";
import { ServiceState } from "@shared/wega-utils/wega-enums";

export class UrlService implements MapService {
  state: ServiceState;
  canUseFilter: boolean = false;

  getLayer(): Promise<any> {
    throw new Error("Method not implemented.");
  }

  getAttributesByID(featureID: string): Promise<any> {
    throw new Error("Method not implemented.");
  }

  loadLayerInfo(): Promise<FieldsInfo> {
    throw new Error("Method not implemented.");
  }

  getPointFeatureInfo(pCoordinates: any): Promise<any> {
    throw new Error("Method not implemented.");
  }

  getFeaturesByQuery(pFilter: ResourceFilter): Promise<[QueryFeature[], boolean]> {
    throw new Error("Method not implemented.");
  }

  setFilter(pFilter: any) {
    throw new Error("Method not implemented.");
  }

  saveFeatures(QueryFeature: any): Promise<boolean> {
    throw new Error("Method not implemented.");
  }

  constructor(pConfig: ConfigService) {}

  getExtentFeatureInfo(pExtent: GeoExtent): Promise<QueryFeature[]> {
    throw new Error("Method not implemented.");
  }

  public getLegend(): LegendDescription[] {
    return null;
  }

  public async loadSpatialData(): Promise<LayerData[]> {
    return null;
  }
}
