import { eSpatialRelationship } from "@shared/wega-utils/wega-enums";
import { FilterRecord } from "./filter-record";
import { FilterSpatial } from "./filter-spatial";
import { EsriProvider } from "@wega-providers/esri.provider";
import { WegaUtils } from "@shared/wega-utils/wega-utils";

export class ResourceFilter {
  public filterRecords: FilterRecord[] = [];
  public hasSpatialFilter: boolean;
  public spatialFilter: FilterSpatial = null;
  public union: boolean = false;

  private _sql: string;
  private _sqlCaseSensitive: string;

  public get sql(): string {
    this.rebuildSql();
    return this._sql;
  }

  public get sqlWms(): string {
    this.rebuildSql();
    return this._sqlCaseSensitive;
  }

  public set sql(value: string) {
    this._sql = value;
    // this.FromSQL(_sql);
  }

  constructor(public esri: EsriProvider) {}

  public reset() {
    this.sql = "";
    this.filterRecords = [];
    this.spatialFilter = null;
    this.hasSpatialFilter = false;
  }

  public getSpatialRelationship(): eSpatialRelationship {
    if (this.spatialFilter) {
      return this.spatialFilter.relation;
    }

    return null;
  }

  public getEsriGeometry(): any {
    return this.spatialFilter.geometry;
  }

  public setSpatial(relation: string, geometry: any) {
    if (relation && geometry) {
      this.spatialFilter = new FilterSpatial(relation, WegaUtils.createGuid(), geometry, this.esri);
      this.hasSpatialFilter = true;
    } else {
      this.spatialFilter = null;
      this.hasSpatialFilter = false;
    }
  }

  public addCondition(sqlString: string) {
    let eq = sqlString.split("=");
    if (eq.length === 2) {
      this.addRecord(new FilterRecord(eq[0].trim(), eq[1].trim(), "="));
      return;
    }

    eq = sqlString.split(" not like ");
    if (eq.length === 2) {
      this.addRecord(new FilterRecord(eq[0].trim(), eq[1].trim(), "not like"));
      return;
    }

    eq = sqlString.split(" like ");
    if (eq.length === 2) {
      this.addRecord(new FilterRecord(eq[0].trim(), eq[1].trim(), "like"));
      return;
    }

    eq = sqlString.split(">");
    if (eq.length === 2) {
      this.addRecord(new FilterRecord(eq[0].trim(), eq[1].trim(), ">"));
      return;
    }

    eq = sqlString.split("<");
    if (eq.length === 2) {
      this.addRecord(new FilterRecord(eq[0].trim(), eq[1].trim(), "<"));
      return;
    }
  }

  public addRecord(condition: FilterRecord) {
    this.filterRecords.push(condition);

    this.rebuildSql();
  }

  public addConditionStructure(fieldName: string, operator: string, value: string) {
    const newCond = new FilterRecord(fieldName, value, operator);

    this.addRecord(newCond);
  }

  rebuildSql() {
    this._sql = "1=1";
    this._sqlCaseSensitive = "1=1";
    //const nullCondition = new FilterRecord('1', '1');

    if (this.filterRecords.length > 0) {
      if (this.union) {
        this._sql += " AND <union>";
      }

      let index = 0;
      for (const condition of this.filterRecords) {
        const sqlCondition = condition.getSqlRecord();
        const sqlConditionCaseSense = condition.getSqlRecord(true);

        if (condition.enabled && sqlCondition) {
          this._sql += ` ${!this.union || index > 0 ? condition.sequenceCondition : ""} ${sqlCondition} `;
          this._sqlCaseSensitive += ` ${condition.sequenceCondition} ${sqlConditionCaseSense} `;
        }

        index++;
      }

      if (this.union) {
        this._sql = this._sql.trim() + "</union>";
      }

      this._sql = this._sql.replace("<union>", " (");
      this._sql = this._sql.replace("</union>", ")");
    }
  }
}
