import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { loadModules } from "esri-loader";
import { LegendDescription } from "../../legend/legend-description";
import { ResourceFilter } from "../../filter/resource-filter";
import { FieldsInfo } from "../../feature/fields-info";
import { QueryFeature } from "../../feature/query-feature";
import { EsriProvider } from "@wega-providers/esri.provider";
import { WebClientProvider } from "@shared/providers/web-client.provider";
import { AppConfigProvider } from "@shared/providers/config.provider";
import { MapService } from "@domain/data/resource/map-service";
import { ConfigService } from "@shared/config/config-service";
import { LayerData } from "@domain/data/structures/layer-data";
import { MapClickPoint } from "@domain/data/structures/map-click-point";
import { FieldConfig } from "@shared/config/config-field";
import { WegaServiceCapabilities } from "../service-capabilities";
import { LocaleProvider } from "src/app/modules/i18n/providers/i18n.provider";
import { GenericService } from "../generic-service";
import { environment } from "src/environments/environment";
import { ServiceType, ServiceState, eSpatialRelationship } from "@shared/wega-utils/wega-enums";
import { LayerProvider } from "@wega-providers/layer.provider";

export class ArcGisService implements MapService {
  constructor(public service: GenericService) {
    this.config = service.getConfig();
    this.webClient = service.web;
    this.esri = service.esri;
    this.globalConfig = service.appConfig;
    this.capabilities = service.capabilities;
    this.locale = service.locale;
    this.layerProvider = service.layerProvider;

    this.tiled = this.config.type === ServiceType.arcgistiled;

    this.esri.setProxy(this.config);
  }

  state: ServiceState;
  canUseFilter: boolean = true;

  config: ConfigService;
  webClient: WebClientProvider;
  esri: EsriProvider;
  globalConfig: AppConfigProvider;
  capabilities: WegaServiceCapabilities;
  locale: LocaleProvider;
  layerProvider: LayerProvider;

  tiled: boolean;
  layer: any;

  maxRecordsCount = 10000;

  private _arcGISLayersInfo: {} = {};

  layerLegend: LegendDescription[] = [];
  public getArcGISLayersInfo() {
    return this._arcGISLayersInfo;
  }

  public getLegend(): LegendDescription[] {
    return this.layerLegend;
  }

  async getLayer(): Promise<any> {
    const [ArcGISDynamicMapServiceLayer, ArcGISTiledMapServiceLayer, ImageParameters] = await loadModules([
      "esri/layers/ArcGISDynamicMapServiceLayer",
      "esri/layers/ArcGISTiledMapServiceLayer",
      "esri/layers/ImageParameters",
    ]);

    const imgParams = new ImageParameters();

    if (this.config.startLayers) {
      imgParams.layerOption = ImageParameters.LAYER_OPTION_SHOW;
      imgParams.layerIds = this.config.startLayers;
    } else if (this.config.layers) {
      imgParams.layerOption = ImageParameters.LAYER_OPTION_SHOW;
      imgParams.layerIds = this.config.layers;
    }

    const ctor = this.tiled ? ArcGISTiledMapServiceLayer : ArcGISDynamicMapServiceLayer;
    // const me = this;
    // ctor.prototype.getImageUrl = function (extent, width, height, callback) {
    //   callback(me.config.encodeProxyRequest
    //     ? me.webClient.createProxiedUrl(this._url.path, me.config.proxy, this.config.encodeProxyRequest)
    //     : this._url.path
    //   );
    // };

    this.layer = new ctor(this.config.url, {
      imageParameters: imgParams,
    });

    let loaded = false;
    if (this.config.hideLayers.length > 0) {
      this.layer.on("load", () => {
        if (!loaded) {
          if (this.layer.visibleLayers) {
            const newVisibleLayers = this.layer.visibleLayers.filter((vl) => this.config.hideLayers.indexOf(vl.toString()) == -1);
            this.layer.setVisibleLayers(newVisibleLayers);
          }

          loaded = true;
        }
      });
    }
    // this.layer.show();
    return this.layer;
  }

  async getLayersForLoading() {
    const layerInfoArray = await this.layerInfo();
    if (!!this.config.layers) {
      const configLayers = this.config.layers;

      // если это групповые слои, то нужно извлечь из них список конечных слоев
      const layers = this.layerProvider.extractNonGroupLayers(layerInfoArray.layers, configLayers);
      return layers;
    }

    return layerInfoArray.layers.map((l) => l.id.toString());
  }

  async loadSpatialData(layer: string): Promise<LayerData[]> {
    const response: LayerData[] = [];
    let layersForLoading = !!layer ? [layer] : await this.getLayersForLoading();

    for await (const layerId of layersForLoading) {
      const newLayerData = await this.loadSpatialDataLayer(layerId);
      newLayerData.name = layerId;

      response.push(newLayerData);
    }

    return response;
  }

  async loadSpatialDataLayerFromStatistics(layerId: string): Promise<LayerData> {
    const response = new LayerData();
    const objectIdField = this.getOIDField(layerId);

    if (objectIdField) {
      const maxRecordsCount = 1000;
      const minMaxId = await this.getLayerStat(
        layerId,
        JSON.stringify([
          {
            statisticType: "min",
            onStatisticField: objectIdField,
            outStatisticFieldName: "MINID",
          },
          {
            statisticType: "max",
            onStatisticField: objectIdField,
            outStatisticFieldName: "MAXID",
          },
        ])
      );

      const minId = minMaxId[0]["attributes"]["MINID"];
      const maxId = minMaxId[0]["attributes"]["MAXID"];

      for (let objectId = minId; objectId < maxId; objectId += maxRecordsCount) {
        const filter = new ResourceFilter(this.esri);

        filter.addConditionStructure(objectIdField, ">=", objectId);
        filter.addConditionStructure(objectIdField, "<", objectId + maxRecordsCount);

        const [features, isError] = await this.getLayerFeaturesByQuery(filter, layerId);
        response.addArcGISFeatures(features);
      }
    }

    return response;
  }

  async loadSpatialDataLayer(layerId: string): Promise<LayerData> {
    let response = new LayerData();
    const objectIdField = this.getOIDField(layerId);

    let totalRecords = -1;
    let records = [];

    if (objectIdField) {
      try {
        const recordIds = await this.getLayerRecordIds(layerId);

        totalRecords = recordIds.length;
        records = recordIds.sort((x, y) => x - y);

        !environment.production && console.info(`Точное число записей для слоя '${layerId}': ${totalRecords}.`);
      } catch { }

      if (totalRecords == -1) {
        !environment.production && console.warn(`Не удалось извлечь точное число записей для слоя '${layerId}', будет использован альтернативный алгоритм!`);
      }

      const maxRecordsCount = 1000;
      let minId = totalRecords == -1 ? 0 : records[0];
      let maxId = minId + maxRecordsCount;

      let failedAttempts = 10;
      do {
        const filter = new ResourceFilter(this.esri);
        filter.addConditionStructure(objectIdField, ">=", `${minId}`);
        filter.addConditionStructure(objectIdField, "<", `${maxId}`);

        const [features, error] = await this.getLayerFeaturesByQuery(filter, layerId);

        if (!error) {
          if (features.length > 0) {
            response.addArcGISFeatures(features);
          } else {
            failedAttempts--;
          }
        } else {
          /// попробовать другой вариант: считывание всех записей по одному
          !environment.production &&
            console.warn(
              `Не удалось извлечь записи слоя '${layerId}'через стандартный алгоритм, поэтому будет использован метод извлечения одиночных записей.`
            );

          const [Polygon, SpatialReference] = await this.esri.loadModules(["esri/geometry/Polygon", "esri/SpatialReference"]);

          response = new LayerData();
          for await (const recordId of records) {
            const feature = await this.getLayerRecord(layerId, recordId);
            const features: QueryFeature[] = this.arcGisFeatureToQueryFeature([feature]);

            response.addArcGISFeatures(features);
          }
        }

        minId = maxId;
        maxId += maxRecordsCount;
      } while (totalRecords == -1 ? failedAttempts > 0 : response.featuresList.length < totalRecords);
    }

    return response;
  }

  public getOIDField(layerId: string): string {
    const fieldsList = this._arcGISLayersInfo[layerId.toString()]?.fields;
    if (!fieldsList) {
      return null;
    }

    let oidField = "OBJECTID";

    for (const fld of fieldsList) {
      if (fld.type.indexOf("OID") !== -1 || fld.type.indexOf("FID") !== -1) {
        oidField = fld.name;
      }
    }

    return oidField;
  }

  async getLayerStat(layerId: string, statString: string, condition: string = null) {
    let url =
      this.config.url +
      "/" +
      layerId +
      "/query?f=json" +
      "&returnGeometry=false" +
      "&returnIdsOnly=false" +
      "&returnCountOnly=false" +
      "&outStatistics=" +
      encodeURIComponent(statString);

    if (condition) {
      url = url + "&where=" + condition + "";
    }

    url = this.webClient.createProxiedUrl(url, this.config.proxy, this.config.encodeProxyRequest);
    const agsResponse = await this.webClient.httpGet<any>(url);
    // let agsResponse = await this.web.httpGetWithProxy(url, this.globalConfig.Environment.CorsScript);

    return agsResponse.features;
  }

  async getLayerRecordIds(layerId: string) {
    let url = this.config.url + "/" + layerId + "/query?f=json" + "&returnIdsOnly=true" + "&where=" + encodeURIComponent("1 = 1");

    url = this.webClient.createProxiedUrl(url, this.config.proxy, this.config.encodeProxyRequest);
    const agsResponse = await this.webClient.httpGet<any>(url);
    // let agsResponse = await this.web.httpGetWithProxy(url, this.globalConfig.Environment.CorsScript);

    return agsResponse.objectIds;
  }

  async getLayerRecord(layerId: string, recordId: string) {
    let url = `${this.config.url}/${layerId}/${recordId}?f=json`;
    url = this.webClient.createProxiedUrl(url, this.config.proxy, this.config.encodeProxyRequest);
    const agsResponse = await this.webClient.httpGet<any>(url);
    return agsResponse.feature;
  }

  async layerInfo() {
    let infoUrl = this.webClient.createProxiedUrl(this.config.url + "/layers?f=json", this.config.proxy, this.config.encodeProxyRequest);
    const layerInfoArray = await this.webClient.httpGet<any>(infoUrl);
    return layerInfoArray;
  }

  async loadLayerInfo(): Promise<FieldsInfo> {
    const fields: FieldsInfo = new FieldsInfo();
    const layerInfoArray = await this.layerInfo();

    const getLegendUrl = this.webClient.createProxiedUrl(this.config.url + "/legend?f=pjson", this.config.proxy, this.config.encodeProxyRequest);
    const legendInfoArray = await this.webClient.httpGet<any>(getLegendUrl);

    const relationsList = {};
    const module = this;

    for (const layerInfo of layerInfoArray.layers) {
      const layerId = layerInfo.id.toString();

      /// не индексировать данные для тех слоев, которых нет в явно заданном списке слоеа
      if (module.config.layers && module.config.layers.length > 0 && null === module.config.layers.find((l) => l.toString() == layerId)) {
        continue;
      }

      /// cохраняются данные о слоях - в т.ч. для того,
      /// чтобы определить максимальное количество записей, которые можно вернуть
      this._arcGISLayersInfo[layerId] = layerInfo;

      /// Формируем легенду
      const newLayerLegend = new LegendDescription();
      newLayerLegend.name = this.locale.current !== "en" ? "Слой: " + layerInfo.name + " [" + layerId + "]" : "Layer: " + layerInfo.name + " [" + layerId + "]";
      newLayerLegend.layer = layerInfo;
      await newLayerLegend.fromArcGISLegendJson(legendInfoArray, layerId, this.config);

      // if (!!layerInfo.drawingInfo) {
      //   newLayerLegend.fromArcGISJson(layerInfo.drawingInfo.renderer, this.config);
      // }

      const showLegend = (config: ConfigService, layerId: string) =>
        config.hideLegends.length == 0 || (config.hideLegends[0] != "*" && !config.hideLegends.find((l) => l.toString().trim() == layerId.toString().trim()));

      if (showLegend(this.config, layerId)) {
        this.layerLegend.push(newLayerLegend);
      }

      /// Формируется список полей (для поиска)
      try {
        if (layerInfo.fields && Array.isArray(layerInfo.fields)) {
          for (const fieldInfo of layerInfo.fields) {
            const newFieldDesc = new FieldConfig();

            newFieldDesc.name = fieldInfo.alias;
            newFieldDesc.type = this.getFieldTypeFromAG(fieldInfo.type);
            newFieldDesc.filterName = fieldInfo.name;
            newFieldDesc.title = fieldInfo.alias;
            newFieldDesc.layer = layerId;
            newFieldDesc.service = this.service;

            if (fieldInfo.domain && fieldInfo.domain.codedValues) {
              newFieldDesc.addArcGISCodedValues(fieldInfo.domain.codedValues);
            } else {
              newFieldDesc.addUniqueValuesQuery(async (value) => {
                const layerName = layerId;
                const fieldName = fieldInfo.name;
                const values = await this.getUniqueValues(layerName, fieldName, value);

                return values;
              });
            }

            fields.addField(newFieldDesc);
          }
        }
      } catch (e) {
        const message = (e as Error).message;
        console.log(`Ошибка чтения информации о поле (${message})`);
      }

      /// формируем список доп.связей (для вытягивания доп. информации)
      // relationsList[layerName] = [];
      // if (layerInfo.relationships && layerInfo.relationships.forEach) {
      //   layerInfo.relationships.forEach(function (relationInfo) {
      //     var relationId = relationInfo.id;
      //     var cardinality = relationInfo.cardinality
      //     relationsList[layerName].push(relationInfo);
      //   });
      // }
    }

    this.injectRequiredFonts();
    return fields;
  }

  injectRequiredFonts() {
    Object.keys(this.config.requiredFonts).forEach((fontName) => {
      const fontPath = this.config.requiredFonts[fontName];
      const style = `@font-face {font-family: '${fontName}'; src: url(${fontPath}) format('truetype');}`;
      const sheet = document.createElement("style");

      sheet.innerHTML = style;
      document.body.appendChild(sheet);
    });
  }

  public async getUniqueValues(layerID: string, fieldName: string, userInput: string) {
    let url =
      this.config.url +
      "/" +
      layerID +
      "/query?f=json" +
      "&outFields=" +
      fieldName +
      "&returnGeometry=false" +
      "&returnIdsOnly=false" +
      "&returnCountOnly=false" +
      "&orderByFields=" +
      fieldName +
      "&groupByFieldsForStatistics=" +
      fieldName +
      // "&outStatistics=%5B%7B%0D%0A+%22statisticType%22%3A+%22count%22%2C%0D%0A+%22onStatisticField%22%3A+%22"
      // + pFieldName + "%22%2C+%22outStatisticFieldName%22%3A+%22Count%22%0D%0A%7D%5D" +
      "&returnDistinctValues=true" +
      "&where=" +
      fieldName +
      "+like+'%" +
      userInput +
      "%'";

    const agsResponse = await this.webClient.httpGet<any>(url);
    // let agsResponse = await this.web.httpGetWithProxy(url, this.globalConfig.Environment.CorsScript);

    const values = [];
    if (agsResponse.features) {
      for (const ftr of agsResponse.features) {
        const val = ftr["attributes"][fieldName];
        const cnt = ftr["attributes"]["COUNTDISTINCT"];

        values.push({
          name: val + " (" + (!!cnt ? cnt : "-") + ")",
          code: val,
        });
      }
    }

    return values;
  }

  getFieldTypeFromAG(type: string): string {
    return type.toLowerCase().replace("esrifieldtype", "");
  }

  getAttributesByID(featureID: string): Promise<any> {
    // throw new Error("Method not implemented.");
    return null;
  }

  async getPointFeatureInfo(point: MapClickPoint): Promise<QueryFeature[]> {
    const [MapPoint, Extent] = await loadModules(["esri/geometry/Point", "esri/geometry/Extent"]);

    const params = {
      text: undefined,
      width: point.mapWidth,
      height: point.mapHeight,
      geometry: MapPoint({
        x: point.x,
        y: point.y,
        spatialReference: { wkid: point.srs },
      }),
      extent: new Extent({
        xmin: point.x - point.extentXDiff,
        ymin: point.y - point.extentYDiff,
        xmax: point.x + point.extentXDiff,
        ymax: point.y + point.extentYDiff,
        spatialReference: { wkid: point.srs },
      }),
    };

    return this.getFeatureInfo(params);
  }

  async getExtentFeatureInfo(evt: any): Promise<QueryFeature[]> {
    const params = {
      width: evt.target.map.width,
      height: evt.target.map.height,
      extent: evt.target.map.extent,
      geometry: evt.geometry,
      text: evt.text,
    };

    return this.getFeatureInfo(params);
  }

  private async getFeatureInfo({ width, height, geometry, extent, text }): Promise<QueryFeature[]> {
    const [ArcGISDynamicMapServiceLayer, FeatureLayer, ImageParameters, RelationshipQuery, IdentifyTask, IdentifyParameters, MapPoint, Extent] =
      await loadModules([
        "esri/layers/ArcGISDynamicMapServiceLayer",
        "esri/layers/FeatureLayer",
        "esri/layers/ImageParameters",
        "esri/tasks/RelationshipQuery",
        "esri/tasks/IdentifyTask",
        "esri/tasks/IdentifyParameters",
        "esri/geometry/Point",
        "esri/geometry/Extent",
      ]);

    const idTask = new IdentifyTask(this.config.url);
    const params = new IdentifyParameters();

    params.tolerance = 10;
    params.layerOption = IdentifyParameters.LAYER_OPTION_VISIBLE; // IdentifyParameters.LAYER_OPTION_ALL
    params.layerDefinitions = this.layer.layerDefinitions;
    params.returnGeometry = true;
    params.layerIds = this.config.queryLayers ?? this.config.layers;

    params.width = width;
    params.height = height;
    params.geometry = geometry;
    params.mapExtent = extent;
    // params.spatialReference = { "wkid": pCoordinates.srs };

    const idResultList = await new Promise<any>((resolve, reject) => {
      idTask.execute(
        params,
        (response: any[]) => {
          if (!response.length) {
            reject(this.locale.current !== "en" ? "Результаты отсутствуют!" : "No results!");
          } else {
            if (response.length > 0) {
              const feature = [];

              response.forEach((obj) => {
                const _feature = Object.assign({}, obj.feature);

                _feature.layerId = obj.layerId;
                _feature.layerName = obj.layerName;

                const textFound = this.containsText(_feature, text);
                if (textFound) {
                  feature.push(_feature);
                }
              });

              resolve(feature);
            }
          }
        },
        (error: any) => {
          console.warn(error);
          reject(error);
        }
      );
    });

    const features: QueryFeature[] = this.arcGisFeatureToQueryFeature(idResultList);
    return features;
  }

  containsText(feature: any, _text: string) {
    if (!_text) {
      return true;
    }

    let found = false;
    const text = _text.toUpperCase();

    for (const attributeName in feature.attributes) {
      const attributeValue = feature.attributes[attributeName];

      if (!found) {
        found = attributeValue.toUpperCase().indexOf(text) !== -1;
      }
    }

    return found;
  }

  private arcGisFeatureToQueryFeature(resultsList: any[], fieldAliases: any = null) {
    const features: QueryFeature[] = [];

    for (const result of resultsList) {
      const newQueryFeature = new QueryFeature(this.config, this.capabilities, this.locale);

      if (result) {
        newQueryFeature.addArcGISAtributes(this.config.title, result, this.config.fieldID, fieldAliases);

        if (result.geometry) {
          newQueryFeature.geometry = result.geometry;
          result.geometry.toJson && newQueryFeature.setGeometry(result.geometry.toJson());
        }
      }

      features.push(newQueryFeature);
    }

    return features;
  }

  async getFeaturesByQuery(filter: ResourceFilter): Promise<[QueryFeature[], boolean]> {
    let response: QueryFeature[] = [];

    for (const layerId of this.config.layers) {
      const [layerFeatures, isError] = await this.getLayerFeaturesByQuery(filter, layerId);

      if (layerFeatures && layerFeatures.length > 0) {
        response = response.concat(layerFeatures);
      }
    }

    return [response, false];
  }

  async getLayerFeaturesByQuery(filter: ResourceFilter, layerId: string): Promise<[QueryFeature[], boolean]> {
    try {
      const [Query, QueryTask] = await loadModules(["esri/tasks/query", "esri/tasks/QueryTask"]);
      const query = new Query();

      query.where = filter ? filter.sql : '1=1';
      query.outSpatialReference = { wkid: 3857 };
      query.returnGeometry = true;

      if (filter && filter.hasSpatialFilter) {
        query.spatialRelationship = this.getRelationship(filter.getSpatialRelationship());
        query.geometry = filter.getEsriGeometry();
      }

      query.outFields = ["*"];

      const queryTask = new QueryTask(this.config.url + "/" + layerId + "/");
      const queryResult = await queryTask.execute(query);
      const response: QueryFeature[] = this.arcGisFeatureToQueryFeature(queryResult.features, queryResult.fieldAliases);

      return [response, false];
    } catch (error) {
      console.warn(`Ошибка получения данных по запросу: ${error}`);
      return [[], true];
    }
  }

  getRelationship(relation: eSpatialRelationship): string {
    return "esriSpatialRelIntersects";
  }

  setFilter(filter: ResourceFilter) {
    const query = filter.sql;
    let layerDefinitions = [];

    this.layer.layerInfos
      //// l.id.toString нужен т.к. в wega-cat имя слоя хранится как текст, а в ArcGIS это всегда число
      // .filter((l) => (this.layer.visibleLayers.indexOf(l.id.toString()) !== -1 || this.layer.visibleLayers.indexOf(l.id) !== -1) && l.type === "Feature Layer")
      .filter((l) => null == l.subLayerIds && (l.type === "Feature Layer" || l.type === "Annotation Layer"))
      .forEach((l) => {
        layerDefinitions[l.id] = query;
      });

    this.layer.setLayerDefinitions(layerDefinitions);
  }

  saveFeatures(queryFeature: any): Promise<boolean> {
    throw new Error("Method not implemented.");
  }
}
